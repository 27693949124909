@value lightgray, gray, darkgray, lightgreen, green from './colors.css';

.controls {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.step {
  flex-grow: 1;
  flex-basis: 0;
}

.step:nth-of-type(1) {
  text-align: right;
}

.step:nth-of-type(2) {
  text-align: left;
}

.step button {
  font-size: 2em;
}

.step button:disabled {
  opacity: 0.5;
}

.select {
  display: inline-flex;
}

.limit input {
  text-align: right;
}

.placeholder {
  flex-grow: 1;
}

.controls :global .pager__control {
  box-shadow: none;
}

.controls :global .pager__control--is-focused,
.controls :global .pager__control:hover {
  border-color: lightgray;
}

.controls :global .pager__option--is-focused {
  background-color: gray;
}

.controls :global .pager__option--is-selected {
  background-color: green;
}

.controls :global .pager__option:active {
  background-color: darkgray;
  color: white;
}

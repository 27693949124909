@value colors: './colors.css';
@value lightgray, darkgray, default from colors;

.carousel {
  background-color: white;
  padding: 16px 32px;
}

:global .alice-carousel__dots-item {
  background-color: lightgray;
}

:global .alice-carousel__dots-item.__active,
:global .alice-carousel__dots-item:hover {
  background-color: darkgray;
}

.carousel a {
  text-decoration: none;
}

.carousel h2 {
  margin: 0px 4px;
}

.preview {
  height: 248px;
}

.title {
  display: block;
  font-size: 40px;
  line-height: 1.5;
  font-weight: 600;
  color: darkgray;
}

.title:hover {
  color: default;
}

.presenters {
  display: block;
  font-size: 24px;
  font-weight: 500;
  color: darkgray;
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  text-transform: uppercase;
  width: 150px;
}

.class {
  /* height: 48px; */
  color: white;
  background-image: linear-gradient(
    #51AE32,
    #44932A
  );
  border: 1px solid darkgreen;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.class:hover {
  background-image: linear-gradient(
    #44932A,
    #387923
  );
}

.class:active {
  background-image: linear-gradient(
    #387923,
    #2C5F1B
  );
}

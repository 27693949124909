@value darkgray from './colors.css';

.languageSwitcher {
  margin-left: 5px;
  margin-right: 3px;
}

.languageSwitcherButtons {
  height: 100%;
  display: flex;
  position: relative;
}

.languageSwitcherButtons input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.languageSwitcherButtons label {
  cursor: pointer;
  padding: 0 8px;
  height: 26px;
  display: flex;
  align-items: center;
  border-radius: 13px;
}

.languageSwitcherButtons input:checked + label {
  background-color: #333;
  color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
}

.button:hover {
  color: black;
}

.icon {
  margin-top: 0.1em;
}

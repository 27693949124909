@value colors: './colors.css';
@value lightgreen, almostwhite from colors;
@value app: './App.module.css';
@value width-threshold from app;

@value gray #4A4949;
@value lightgray #606060;

ul.navigation {
  margin-top: 32px;
  list-style: none;
  padding: 0;
}

.navigation li {
  height: 48px;
  line-height: 48px;
}

.navigation a {
  padding-left: 20px;
  text-decoration: none;
  color: inherit;
  display: block;
}

.navigation a:active {
  color: white;
}

.navigation a.active {
  background-color: lightgray;
  border-left: 5px solid lightgreen;
}

.navigation a:hover, a:focus {
  background-color: gray;
  outline: none;
}

.icon {
  margin-left: 16px;
  margin-right: 8px;
}

@media (max-width: width-threshold) {
  ul.navigation {
    display: flex;
    margin: 0;
  }

  .navigation li {
    flex: 1 1 0px;
    display: inline-block;
    text-align: center;
    line-height: normal;
    height: 55px;
  }

  .navigation a {
    padding-top: 3px;
    padding-left: 0;
    box-sizing: border-box;
    border-top: 5px solid transparent;
    height: 100%;
  }

  .navigation a.active {
    border-left: none;
    border-color: lightgreen;
  }

  .icon {
    display: block;
    font-size: 20px;
    margin: 0;
  }
}

@value lightgreen, green, lightgray, gray, darkgray, almostwhite from './colors.css';
@value darkgreen #2C5F1B;

@value header-height from './App.module.css';


.button {
  white-space: nowrap;
  padding: 8px;
  text-decoration: none;
}

.button:visited {
  background-color: white;
}

.button:hover {
  color: black;
}

.dropdown {
  margin: 0 5px;
}

.dropdown form {
  position: absolute;
  top: header-height;
  right: 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
}

.logout {
  width: 160px;
  box-sizing: border-box;
  padding: 5px 20px;
  background-color: green;
}

.logout button {
  width: 100%;
  text-align: left;
  color: white;
}

.login {
  width: 200px;
  padding: 8px 16px;
  color: darkgray;
  background-color: gray;
}

.login p {
  margin: 0;
}

.login input, .login button {
  width: 100%;
  margin: 12px 0;
  border-radius: 2px;
  font-weight: inherit;
}

.login input {
  box-sizing: border-box;
  height: 32px;
  padding: 0 8px;
}

@value lightgray, gray, darkgray from './colors.css';

.searchBar {
  margin: 0 auto;
  height: 30px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 5;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  display: flex;
  max-width: 300px;
}

.searchBar:focus-within:before {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.searchBar:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1b242a;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
}

#searchInput, .button {
  box-sizing: border-box;
  height: 100%;
  border: none;
  background-color: white;
  font-size: 18px;
  min-width: 30px;
  border-radius: 0;
}

#searchInput {
  padding: 3px 10px;
}
#searchInput:focus {
  outline: none;
}
#searchInput::placeholder {
  color: #9a9b9c;
}

.button {
  color: darkgray;
  border-left: 1px solid #bcbdbc;
  padding: 0 5px;
}

.button:hover {
  background-color: #eee;
}

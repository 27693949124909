body {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

#root {
  height: 100vh;
}

button {
  cursor: pointer;
}

@value app: './App.module.css';
@value width-threshold from app;

.chat {
  position: relative;
  width: 300px;
  border: 1px solid #44932A;
}

.chatHistory {
  position: absolute;
  padding: 15;
  width: 100%;
  height: calc(100% - (10% + 125px));
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.chatMessage {
  float: floatDirection;
  padding: 6px 10px;
  margin: margin;
  text-align: left;
  overflow-wrap: break-word;
}

.chatMessageHighlight {
  font-weight: bold;
}

.chatMessageTopRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.chatMessageUserName{
  font-weight: bold;
}

.chatMessageTime{
  font-size: 11px;
  color: #7a7a7a;
}

.sendMessage {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
}

.sendMessageField {
  flex-grow: 6;
  resize: none;
}
.sendMessageField::placeholder {
  color: rgb(116, 116, 116);
}

.sendMessageButton {
  flex-grow: 2;
}

.downloadChatLogButton {
  flex-grow: 2;
}

.userList {
  position: absolute;
  width: 100%;
  display: contents;
  z-index: 1;
}

.viewerInfo {
  display: block;
  border-bottom: 1px solid #44932A;
  background-color: #F9F9F9;
  max-height: 125px;
}

.viewerListButton {
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

.downloadUserList {
  width: 100%;
  position: relative;
  z-index: 1;
}

.viewerList {
  border: 1px solid #44932A;
  background-color: #F9F9F9;
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: auto;
  max-height: 250px;
  display: block;
  padding-left: 15px;
}

table > tr{
  padding-left: 5px;
}

@media (max-width: width-threshold) {
  .chat {
    width: 100%;
    height: 500px;
  }

  .chatHistory {
    position: relative;
  }

  .sendMessage {
    position: relative;
  }

  .userList {
    position: relative;
  }
}

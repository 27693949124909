@value lightgray from './colors.css';

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s;
}

.enterDone {
  opacity: 1;
}

:export {
  loadingColor: lightgray;
}

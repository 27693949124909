@value app: './App.module.css';
@value nav-height-mobile, header-height from app;
@value width-threshold from app;

/* We try to make the player iframe exactly 16:9 in aspect ratio. This is
   perfect for the vast majority of videos. The other videos still work fine,
   but waste some space. Furthermore, we need to restrict the size of the video
   player if the screen space is limited. Especially on mobile it's absolutely
   terrible if the user cannot scroll anymore since the video player is
   everywhere and eats all touch inputs. */


.playerWrap {
  position: relative;
  width: 100%;
  /* We calculate the maximum width from the viewport height. We subtract all
     header, fotter and `main` padding and convert it to width via 16:9 aspect
     ratio. This makes for a very pleasent centered video player when first
     loading the page. */
  max-width: calc(16.0 / 9.0 * (100vh - nav-height-mobile - header-height - 16px));
  margin: 0 auto;
}

/* The trick to get the right height for the player is by adding a dummy
   element that has a `padding-top` in percent. This percentage refers to the
   width of the element. */
.heightDummy {
  width: 100%;
  padding-top: calc(9.0 / 16.0 * 100%);
}

.player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

.playerReplacementImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  text-align: center;
  text-decoration: none;
  line-height: 100px; /* Hack to center text vertically */
}

.livestream {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;
}

@media (max-width: width-threshold) {
  .livestream {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }
}

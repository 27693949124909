@value colors: './colors.css';
@value green, lightgray, darkgray, almostwhite, default from colors;
@value gray #F9F9F9;

@value header-height 64px;
@value nav-height-mobile 55px;
@value width-threshold 700px;

.app {
  display: grid;
  grid-template:
    "header header" 0fr
    "nav content" auto
    / 215px;
  height: 100vh;
}

@media (max-width: width-threshold) {
  .app {
    grid-template:
      "header" 0fr
      "content" 1fr
      "nav" 0fr;
    overflow-x: auto;
    height: calc(100vh - nav-height-mobile);
  }

  .header {
    overflow-x: auto;
  }

  .navigation {
    height: 0px;
  }
  .links {
    height: nav-height-mobile;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.header {
  grid-area: header;
  height: header-height;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-weight: 300;
  color: #393b41;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  z-index: 7;
}

.headerIcon {
  height: calc(header-height * 0.4);
  text-align: center;
}

.headerIcon > svg {
  height: 100%;
}

.logo {
  margin: 0;
  margin-right: 10px;
}

.logo img {
  height: calc(header-height * 0.7);
  vertical-align: middle;
}

@media (max-width: width-threshold) {
  .header {
    padding: 0;
  }

  .logo {
    margin-left: 8px;
  }

  .logo img {
    height: calc(header-height * 0.85);
  }
}


.navigation {
  grid-area: nav;
  color: almostwhite;
  display: flex;
  flex-direction: column;
}

.links {
  z-index: 5;
  flex-grow: 1;
  background-color: darkgray;
}

.content {
  grid-area: content;
  padding: 15px;
  padding-left: calc(10px + 4%);
  padding-right: calc(10px + 4%);
  /* `min-width` for grid cells defaults to the content width.
    This breaks the `overflow: hidden` used by the carousel.*/
  min-width: 0;
  background-color: gray;
  color: default;
}

.content h1 {
  margin-top: 5px;
}

a {
  color: darkgray;
}

a:hover {
  text-decoration: none;
  color: #000c1a;
}

@media (max-width: width-threshold) {
  .content {
    padding: 8px;
  }
}

input:not([type]), input[type="text"], input[type="password"] {
  background-color: white;
  border: 1px solid lightgray;
  color: black;
  font-family: inherit;
}

::placeholder {
  color: darkgray;
}

.logoLink:focus {
  background: none;
}

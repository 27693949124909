@value almostwhite, green from './colors.css';
@value width-threshold from './App.module.css';


.item {
  display: flex;
  flex-direction: row;
  margin: 13px 0;
  height: 90px;
  overflow: hidden;
  max-width: 850px;
}
.item:hover {
  background-color: #eee;
}

.itemInfo a {
  text-decoration: none;
}
.itemInfo a:hover {
  color: green;
}

.thumbnail {
  flex-shrink: 0;
  width: 160px;
  height: 100%;
  background-color: black;
  position: relative;
  margin-right: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  line-height: 100px; /* Hack to center text vertically */
}
.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.alignHelper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(235, 235, 235, 0.95);
  border: 1px solid white;
  padding: 0 3px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
}

.itemInfo {
  flex-grow: 1;
  min-width: 0;
}
.itemInfo h3 {
  margin: 0;
}
.itemInfo h3 a {
  font-size: 17px;
  line-height: 22px;
  max-height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.datePresenter {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.circleSep {
  margin: 0 6px;
  font-size: 0.25em;
  vertical-align: middle;
}

@media (min-width: width-threshold) {
  .thumbnail {
    width: 192px;
  }
  .item {
    height: 108px;
  }
  .itemInfo h3 a {
    font-size: 19px;
    line-height: 25px;
    max-height: 50px;
    margin-bottom: 5px;
  }
}

@value cdgray1, cdgray3, cdgray4 from './colors.css';


.item {
  display: block;
  margin: 8px 0;
  padding: 3px 5px;
  background-color: white;
  border: 1px solid cdgray4;
  border-radius: 7px;
  text-decoration: none;
}
.item:hover {
  border: 1px solid cdgray3;
}
.item:active {
  background-color: cdgray1;
  color: white;
}
.item:active:hover {
  color: white;
}

.item > h3 {
  margin: 0;
  text-decoration: underline;
}
.item:hover > h3 {
  text-decoration: none;
}

.item > p {
  margin: 0;
  text-decoration: none;
}
